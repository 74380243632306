import { TApplication } from '@api/types'
import { store } from '@stores'
import { TPaymentProvidersState } from '@stores/types/*'

import { TPaymentProviderResponse } from '../../api/types/TPaymentProviderResponse'
import { TPaymentReceiverInfoResponse } from '../../api/types/TPaymentReceiverInfoResponse'
import { TPersonalData } from '../../api/types/TPersonalData'
import { TSellSenderInfoResponse } from '../../api/types/TSellSenderInfoResponse'

export const payFormService = {
  initPayForm(application: TApplication) {
    store.dispatch.payForm.init({ application })
  },
  initBySession(providers: TPaymentProvidersState) {
    store.dispatch.payForm.initBySession(providers)
  },
}

export const personalDataService = {
  init(data: TPersonalData) {
    store.dispatch.payForm.initPersonalData(data)
  },
}

export const providerService = {
  initPaymentMethods(paymentProviders: TPaymentProviderResponse) {
    store.dispatch.paymentProviders.init(paymentProviders)
  },
}

export const p2pPurchaseService = {
  initP2PReceiverInfo(paymentReceiver: TPaymentReceiverInfoResponse) {
    store.dispatch.p2pPurchase.initP2PReceiverInfo(paymentReceiver)
  },
}

export const p2pSellService = {
  initP2PSenderInfo(sellSender: TSellSenderInfoResponse) {
    store.dispatch.p2pSell.initP2PSenderInfo(sellSender)
  },
}

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EPaymentMethod } from '@api/types'
import { useToken } from '@hooks'
import { Dispatch, RootState } from '@stores'
import { TPaymentProvidersState } from '@stores/types/*'
import { localeNumber } from '@utils'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { SESSION_PROVIDER_KEY } from '../../../../stores/payform/payForm'
import { EPaymentProvider } from '../../../../stores/types/EPaymentProvider'
import { H4 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { CaptionText500 } from '../../../common/text/BodyText'
import iconRu from './assets/iconRU.svg'

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
`

const ProviderBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 0;
  margin-bottom: 16px;
  cursor: pointer;
`

const ProviderRateWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 16px;
  margin-right: 16px;
`

const ProviderRate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  width: fit-content;
  margin-bottom: 24px;
`

const ProviderDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
  flex: 1 0 0;
  margin: 0 16px 16px 16px;
  width: auto;
`

const ProviderRateValue = styled.div`
  align-self: stretch;
  margin-top: 16px;
  font-smooth: never;
`

const ProviderRateCurrency = styled.div<DarkBgClass>`
  align-self: stretch;
  color: ${(bgClass) => bgClass.secondary.default.value};
`

const ProviderCountryIcon = styled.div`
  text-align: right;
  margin-top: 16px;
`

const HeaderTextContainer = styled.div<DarkBgClass>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${(bgClass) => bgClass.secondary.default.value};
  margin: 0 0 16px;
  padding: 0 24px;
`

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const LabelText = styled.div`
  color: #868686;
`

interface StateProps {
  providers: TPaymentProvidersState
}

type Props = StateProps & DispatchProps

interface DispatchProps {
  setPaymentProvider: (paymentProvider: EPaymentProvider) => void
  setRate: (rate: number | undefined) => void
  setLimits: (minLimit: string, maxLimit: string) => void
}

export const ProviderSelectPage = (props: Props) => {
  const redirectTo = useNavigate()

  const goToSourceSelect = (provider: EPaymentProvider) => {
    props.setPaymentProvider(provider)
    sessionStorage.setItem(SESSION_PROVIDER_KEY, provider)
    const currentProvider = (() => {
      if (provider == EPaymentProvider.RT) {
        return props.providers?.RT
      }
      return props.providers?.M3
    })()

    props.setRate(currentProvider?.providerExchangeRate)
    props.setLimits(
      currentProvider?.providerLimit.minLimit.toString() as string,
      currentProvider?.providerLimit.maxLimit.toString() as string,
    )
    redirectTo(`../source-select/`)
  }

  const tokens = useToken()

  return (
    <FlexBoxCenteredLayout>
      <Container {...tokens.color.font.light_bg}>
        <HeaderTextContainer {...tokens.color.font.light_bg}>
          <CaptionText500>Предложения партнёров</CaptionText500>
          <CaptionText500>За 1 USDT</CaptionText500>
        </HeaderTextContainer>
        {props.providers.RT && (
          <ProviderBlock
            onClick={() => goToSourceSelect(EPaymentProvider.RT)}
            data-testid="provider"
          >
            <Island size="M" bgColor="primary">
              <ProviderRateWrapper>
                <ProviderRate>
                  <ProviderRateValue data-testid="provider.rate-value">
                    <H4>
                      {localeNumber(
                        `${props.providers.RT.providerExchangeRate}`,
                      )}{' '}
                      RUB
                    </H4>
                  </ProviderRateValue>
                  <ProviderRateCurrency
                    {...tokens.color.font.light_bg}
                    data-testid="provider.currency"
                  >
                    <CaptionText500>Российский рубль</CaptionText500>
                  </ProviderRateCurrency>
                </ProviderRate>
                <ProviderCountryIcon>
                  <img src={iconRu} width={'32'} height={'32'} alt={'ru'} />
                </ProviderCountryIcon>
              </ProviderRateWrapper>
              <ProviderDescription>
                <TextContainer data-testid="provider.limits">
                  <LabelText>
                    <CaptionText500>Лимит</CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {localeNumber(
                      props.providers.RT.providerLimit.minLimit.toString(),
                    )}
                    {' – '}
                    {localeNumber(
                      props.providers.RT.providerLimit.maxLimit.toString(),
                    )}{' '}
                    ₽
                  </CaptionText500>
                </TextContainer>
                <TextContainer data-testid="provider.payment-methods">
                  <LabelText>
                    <CaptionText500>Оплата</CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {props.providers.RT.providerPaymentMethods
                      .map(mapPaymentMethodToText)
                      .join(', ')}
                  </CaptionText500>
                </TextContainer>
              </ProviderDescription>
            </Island>
          </ProviderBlock>
        )}
        {props.providers.M3 && (
          <ProviderBlock
            onClick={() => goToSourceSelect(EPaymentProvider.M3)}
            data-testid="provider"
          >
            <Island size="M" bgColor="primary">
              <ProviderRateWrapper>
                <ProviderRate>
                  <ProviderRateValue data-testid="provider.rate-value">
                    <H4>
                      {localeNumber(
                        `${props.providers.M3.providerExchangeRate}`,
                      )}{' '}
                      RUB
                    </H4>
                  </ProviderRateValue>
                  <ProviderRateCurrency
                    {...tokens.color.font.light_bg}
                    data-testid="provider.currency"
                  >
                    <CaptionText500>Российский рубль</CaptionText500>
                  </ProviderRateCurrency>
                </ProviderRate>
                <ProviderCountryIcon>
                  <img src={iconRu} width={'32'} height={'32'} alt={'ru'} />
                </ProviderCountryIcon>
              </ProviderRateWrapper>
              <ProviderDescription>
                <TextContainer data-testid="provider.limits">
                  <LabelText>
                    <CaptionText500>Лимит</CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {localeNumber(
                      props.providers.M3.providerLimit.minLimit.toString(),
                    )}
                    {' – '}
                    {localeNumber(
                      props.providers.M3.providerLimit.maxLimit.toString(),
                    )}{' '}
                    ₽
                  </CaptionText500>
                </TextContainer>
                <TextContainer data-testid="provider.payment-methods">
                  <LabelText>
                    <CaptionText500>Оплата</CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {props.providers.M3.providerPaymentMethods
                      .map(mapPaymentMethodToText)
                      .join(', ')}
                  </CaptionText500>
                </TextContainer>
              </ProviderDescription>
            </Island>
          </ProviderBlock>
        )}
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  providers: state.paymentProviders,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPaymentProvider: (paymentProvider: EPaymentProvider) =>
      dispatch.payForm.set({
        paymentProvider,
      }),
    setRate: (rate: number | undefined) =>
      dispatch.payForm.set({
        rate,
      }),
    setLimits: (minLimit: string, maxLimit: string) => {
      dispatch.payForm.set({
        minLimit,
        maxLimit,
      })
    },
  }
}

const mapPaymentMethodToText = (method: EPaymentMethod): string => {
  switch (method) {
    case EPaymentMethod.BANK_CARD:
      return 'Все банки'
    case EPaymentMethod.SBP:
      return 'СБП'
    default:
      throw new Error('unexpected EPaymentSource')
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSelectPage)

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { EPaymentSource } from '@stores/types/EPaymentSource'
import {
  AuthPage,
  CancelPage,
  ErrorPage,
  OffersNotFoundPage,
  PendingPage,
  SourcePage,
  SuccessPage,
} from 'main/ts/view/pages'

import { ClosePage } from '../view/pages/common/close-page/ClosePage'
import { InterruptPaymentPage } from '../view/pages/common/error-page/InterruptPaymentPage'
import { NotFoundPage } from '../view/pages/common/error-page/NotFoundPage'
import { CancelConfirmationPage } from '../view/pages/p2p/cancel-confirmation-page/CancelConfirmationPage'
import FindOffersPage from '../view/pages/p2p/find-offers-page/FindOffersPage'
import PaymentPage from '../view/pages/p2p/payment-page/PaymentPage'
import ProviderSelectPage from '../view/pages/p2p/provider-select-page/ProviderSelectPage'
import SourceCardPage from '../view/pages/p2p/source-page/source-card-page/SourceCardPage'
import SourceSbpPage from '../view/pages/p2p/source-page/source-sbp-page/SourceSbpPage'
import SourceSelectPage from '../view/pages/p2p/source-select-page/SourceSelectPage'
import SumPage from '../view/pages/p2p/sum-page/SumPage'
import ApplicationPurchaseCreatorPage from '../view/pages/purchase/ApplicationPurchaseCreatorPage'
import P2PPurchasePage from '../view/pages/purchase/p2p-purchase-page/P2PPurchasePage'
import { P2PPurchaseWrapperPage } from '../view/pages/purchase/P2PPurchaseWrapperPage'
import PurchaseWrapperPage from '../view/pages/purchase/PurchaseWrapperPage'
import ApplicationSellCreatorPage from '../view/pages/sell/ApplicationSellCreatorPage'
import P2PSellPage from '../view/pages/sell/p2p-sell-page/P2PSellPage'
import { P2PSellWrapperPage } from '../view/pages/sell/P2PSellWrapperPage'
import { ShopWrapperPage } from '../view/pages/shop/ShopWrapperPage'

const router = createBrowserRouter([
  {
    path: '/purchase',
    element: <ApplicationPurchaseCreatorPage />,
  },
  {
    path: '/sell',
    element: <ApplicationSellCreatorPage />,
  },
  {
    path: '/application/:applicationId/purchase',
    element: <PurchaseWrapperPage />,
    children: [
      {
        path: 'error',
        element: <ErrorPage tip={'Обновите страницу или зайдите позже'} />,
      },
      {
        path: 'payment',
        element: <PaymentPage />,
      },
      {
        path: 'failure',
        element: (
          <ErrorPage
            title={'Платеж не\u00A0прошёл'}
            tip="Попробуйте ещё раз"
            data-testid="result-page.failure"
          />
        ),
      },
      {
        path: 'source-select',
        element: <SourceSelectPage />,
      },
      {
        path: 'sum',
        element: <SumPage />,
      },
      {
        path: `provider-select`,
        element: <ProviderSelectPage />,
      },
      {
        path: 'source',
        element: <SourcePage />,
        children: [
          {
            path: EPaymentSource.CARD,
            element: <SourceCardPage />,
          },
          {
            path: EPaymentSource.SBP,
            element: <SourceSbpPage />,
          },
        ],
      },
      {
        path: 'timeout',
        element: <InterruptPaymentPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: '/application/:applicationId/payment/:paymentId', // todo: заменить payment на purchase (при формировании paymentUrl на бэке)
    element: <P2PPurchaseWrapperPage />,
    children: [
      {
        path: 'p2p-bill',
        element: <P2PPurchasePage />,
      },
      {
        path: 'failure',
        element: (
          <ErrorPage title={'Платеж не\u00A0прошёл'} tip="Попробуйте ещё раз" />
        ),
      },
      {
        path: 'success',
        element: (
          <SuccessPage
            title="Оплачено"
            message={'Монеты зачислятся на баланс\nкошелька в течение 10 минут'}
          />
        ),
      },
      {
        path: 'pending',
        element: <PendingPage />,
      },
      {
        path: 'cancel',
        element: <CancelPage />,
      },
      {
        path: 'confirm-cancel',
        element: <CancelConfirmationPage />,
      },
      {
        path: 'timeout',
        element: <InterruptPaymentPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: '/application/:applicationId/sell/:paymentId',
    element: <P2PSellWrapperPage />,
    children: [
      {
        path: 'p2p-sell',
        element: <P2PSellPage />,
      },
    ],
  },
  {
    path: '/application/:applicationId/shop',
    element: <ShopWrapperPage />,
    children: [],
  },
  {
    path: 'application/close',
    element: <ClosePage />,
  },
  {
    path: 'application/:applicationId/purchase/offers-not-found',
    element: <OffersNotFoundPage />,
  },
  {
    path: 'application/:applicationId/purchase/find-offers',
    element: <FindOffersPage />,
  },
  {
    path: '/auth',
    element: <AuthPage />,
  },
  {
    path: 'error',
    element: <ErrorPage tip={'Обновите страницу или зайдите позже'} />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export const Router = () => {
  return <RouterProvider router={router} />
}

import { TApplication } from '@api/types'
import { createModel } from '@rematch/core'
import { RootModel } from '@stores'
import {
  paymentSourceByString,
  TPayFormState,
  TPaymentProvidersState,
} from '@stores/types/TPayFormState'

import { TPersonalData } from '../../api/types/TPersonalData'
import {
  EPaymentProvider,
  paymentProviderByString,
} from '../types/EPaymentProvider'

export const SESSION_PHONE_KEY = 'SESSION_PHONE'
export const SESSION_SENDER_KEY = 'SESSION_SENDER'
export const SESSION_LAST4PAN_KEY = 'SESSION_LAST4PAN'
export const SESSION_PROVIDER_KEY = 'SESSION_PROVIDER'
export const SESSION_SOURCE_KEY = 'SESSION_SOURCE'

export const payForm = createModel<RootModel>()({
  state: {
    amount: '0',
    paymentBlocking: false,
  } as TPayFormState,
  reducers: {
    set(state, payload: TPayFormState) {
      return { ...state, ...payload }
    },
    setIfEmpty(state, payload: TPayFormState) {
      return {
        ...state,
        ...Object.fromEntries(
          Object.entries(payload).map(([key, value]) => {
            return [key, state[key as keyof TPayFormState] ?? value]
          }),
        ),
      }
    },
  },
  effects: (dispatch) => ({
    init(payload: { application: TApplication }) {
      dispatch.payForm.set({
        ...payload,
      } as TPayFormState)
    },
    initBySession(providers: TPaymentProvidersState) {
      const sessionPhone = sessionStorage.getItem(SESSION_PHONE_KEY)
      const sessionSender = sessionStorage.getItem(SESSION_SENDER_KEY)
      const sessionLast4Pan = sessionStorage.getItem(SESSION_LAST4PAN_KEY)
      const sessionProvider = sessionStorage.getItem(SESSION_PROVIDER_KEY)
      const sessionSource = sessionStorage.getItem(SESSION_SOURCE_KEY)
      const currentProvider = (() => {
        if (sessionProvider == EPaymentProvider.RT) {
          return providers?.RT
        }
        return providers?.M3
      })()

      if (sessionPhone) {
        dispatch.payForm.set({ phone: sessionPhone })
      }
      if (sessionSender) {
        dispatch.payForm.set({ sender: sessionSender })
      }
      if (sessionLast4Pan) {
        dispatch.payForm.set({ last4pan: sessionLast4Pan })
      }
      if (sessionSource) {
        dispatch.payForm.set({
          paymentSource: paymentSourceByString(sessionSource),
        })
      }
      if (sessionProvider) {
        dispatch.payForm.set({
          paymentProvider: paymentProviderByString(sessionProvider),
          rate: currentProvider?.providerExchangeRate,
          minLimit: currentProvider?.providerLimit.minLimit.toString(),
          maxLimit: currentProvider?.providerLimit.maxLimit.toString(),
        })
      }
    },
    initPersonalData(payload: TPersonalData) {
      dispatch.payForm.setIfEmpty({
        sender: payload.sender_name,
        phone: payload.sbp_phone?.slice(1), // remove leads "7" for correct insert
        last4pan: payload.last4pan,
      })
    },
  }),
})

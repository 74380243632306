import { useToken } from '@hooks'
import styled from 'styled-components'

import { Color } from '../../../../generated/quicktype/Tokens'
import { H3Weight600 } from '../../common/header/Headings'
import { Outlet } from '../../common/Outlet'

const Header = styled.div<Color>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(color) => color.bg.page.value};
`

const IslandBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px 0;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 600;
`

export const ShopWrapperPage = () => {
  return (
    <>
      <Header {...useToken().color}>
        <IslandBlock>
          <H3Weight600>Пополнение Steam</H3Weight600>
        </IslandBlock>
      </Header>
      <Outlet />
    </>
  )
}

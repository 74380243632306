import { createModel } from '@rematch/core'
import { RootModel } from '@stores'

import { TPaymentReceiverInfoResponse } from '../../api/types/TPaymentReceiverInfoResponse'
import { TPaymentReceiverInfoState } from '../types/TPaymentReceiverInfoState'

export const p2pPurchase = createModel<RootModel>()({
  state: {} as TPaymentReceiverInfoState,
  reducers: {
    set(state, payload: TPaymentReceiverInfoState) {
      return { ...state, ...payload }
    },
  },
  effects: (dispatch) => ({
    initP2PReceiverInfo(payload: TPaymentReceiverInfoResponse) {
      dispatch.p2pPurchase.set({ ...payload })
    },
  }),
})

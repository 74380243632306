import { FC, useEffect } from 'react'

import { env, QWIK_RETURN_URL } from '@utils'

export const ClosePage: FC = () => {
  useEffect(() => {
    window.Telegram.WebApp.close()
    location.href = env(QWIK_RETURN_URL) || ''
  }, [])

  return <></>
}

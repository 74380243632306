import { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'

import { p2pSellService } from '../../../service/payform'
import { Outlet } from '../../common/Outlet'

interface SellSenderState {
  fetched: boolean
}

const sellSenderReducer = (
  state: SellSenderState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sellId: string,
) => {
  if (!state.fetched) {
    /* todo: uncomment after backend will me done for it
    sellApplicationClient
      .sellSender(sellId)
      .then((res) => p2pSellService.initP2PSenderInfo(res))
      */
    const now = new Date(Date.now())
    now.setMinutes(now.getMinutes() + 1)
    now.setSeconds(now.getSeconds() + 50)

    // todo: change to real data from backend after back will me done for it
    p2pSellService.initP2PSenderInfo({
      accountName: 'Инна Ю.',
      bank: 'T-Bank',
      amount: 1_000_000,
      sellExpirationDate: now.toUTCString(),
      sellId: 1,
    })
    return { fetched: true }
  }
  return state
}

export const P2PSellWrapperPage = () => {
  const pathParams = useParams()
  const paymentId = pathParams.paymentId as string

  const initialSellSender = { fetched: false }

  const [, sellSenderDispatch] = useReducer(
    sellSenderReducer,
    initialSellSender,
  )

  useEffect(() => {
    sellSenderDispatch(paymentId)
  }, [paymentId])

  return (
    <>
      <Outlet />
    </>
  )
}

import { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { authClient } from '@api'
import { HttpStatusCode } from 'axios'

import { H4 } from '../../../common/header/Headings'
import { SpinnerLayout } from '../../../common/layout/spinner'

export const AuthPage: FC = () => {
  const redirectTo = useNavigate()
  const [searchParams] = useSearchParams()

  const authUuid = searchParams.get('authUuid')
  const action = searchParams.get('action')

  const [isAuthUuidLoaded, setAuthUuidLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!authUuid) {
      setErrorMessage('authUuid required')
      return
    }
    if (!action) {
      setErrorMessage('action required')
      return
    }
    authClient
      .getToken(authUuid)
      .catch((error) => {
        if (error.response.status === HttpStatusCode.NotFound) {
          redirectTo(`../not-found`)
        }
        setErrorMessage('error getting auth token')
      })
      .then(() => setAuthUuidLoaded(true))
  }, [authUuid, action, redirectTo])

  useEffect(() => {
    if (!isAuthUuidLoaded) {
      return
    }
    redirectTo(`/` + action)
  })

  return (
    <SpinnerLayout>
      {errorMessage ? <H4>{'Что-то пошло не так'}</H4> : <></>}
    </SpinnerLayout>
  )
}

import { httpClient, IHttpClient } from '@api'

import { TSellSenderInfoResponse } from '../types/TSellSenderInfoResponse'

interface ISellApplicationClient {
  sellSender(paymentPublicId: string): Promise<TSellSenderInfoResponse>
}

const sellApplicationClientFactory = (
  client: IHttpClient,
): ISellApplicationClient => {
  return {
    sellSender(sellPublicId: string): Promise<TSellSenderInfoResponse> {
      return client.get(
        `/client/v1/withdrawal-applications/withdrawals/${sellPublicId}/sender`, // todo: переименовать на sell на бэке
      )
    },
  }
}

export const sellApplicationClient: ISellApplicationClient =
  sellApplicationClientFactory(httpClient)

import { useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { topupApplicationClient } from '@api'
import { fromResponse } from '@api/types'
import { RootState } from '@stores'
import { TPayFormState } from '@stores/types/*'

import { payFormService } from '../../../service/payform'

interface StateProps {
  payForm: TPayFormState
}

interface PutApplicationRState {
  requested: boolean
}

const putApplicationReducer = (state: PutApplicationRState) => {
  if (state.requested) {
    return state
  }
  topupApplicationClient.createApplication().then((res) => {
    payFormService.initPayForm(fromResponse(res))
  })
  return { requested: true }
}

const ApplicationPurchaseCreatorPage = (props: StateProps) => {
  const redirectTo = useNavigate()
  const [, putApplicationDispatch] = useReducer(putApplicationReducer, {
    requested: false,
  })

  useEffect(() => {
    putApplicationDispatch()
  }, [])

  useEffect(() => {
    if (!props.payForm.application?.applicationUuid) {
      return
    }
    redirectTo(
      `/application/${props.payForm.application?.applicationUuid}/purchase/payment`,
    )
  }, [props.payForm.application?.applicationUuid, redirectTo])
  return null
}

const mapStateToProps = (state: RootState): StateProps => ({
  payForm: state.payForm,
})

export default connect(mapStateToProps)(ApplicationPurchaseCreatorPage)

import { init, Models, RematchDispatch, RematchRootState } from '@rematch/core'
import { ExtraModelsFromLoading } from '@rematch/loading'

import { p2pPurchase } from './payform/p2pPurchase'
import { p2pSell } from './payform/p2pSell'
import { payForm } from './payform/payForm'
import { paymentProviders } from './payform/paymentProviders'

const debugMode: boolean = JSON.parse(
  process.env.REACT_APP_REDUX_DEBUG || 'false',
)
export interface RootModel extends Models<RootModel> {
  payForm: typeof payForm
  paymentProviders: typeof paymentProviders
  p2pPurchase: typeof p2pPurchase
  p2pSell: typeof p2pSell
}

export const models: RootModel = {
  payForm: payForm,
  paymentProviders: paymentProviders,
  p2pPurchase: p2pPurchase,
  p2pSell: p2pSell,
}

export const store = init<RootModel, ExtraModelsFromLoading<RootModel>>({
  models,
  redux: {
    devtoolOptions: {
      disabled: !debugMode,
    },
    rootReducers: {
      // eslint-disable-next-line unicorn/no-useless-undefined
      RESET_APP: () => undefined,
    },
  },
})

export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
